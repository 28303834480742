import * as React from "react"
import {Scene} from "react-scrollmagic";
import {Tween} from "react-gsap";

const LogoMaffleMobile = (props) => (

    <Scene
        duration={1000}
        pin={{pushFollowers: true}}
        triggerHook={0.7}
        offset={500}
        indicators={false}
    >
        <Tween from={{
            opacity: 1
        }} to={{
            scale: () => {
                return 1 + 10 / 20;
            },
            opacity: 0,
            ease: "Power2.easeOut"
        }}>

            <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="330 0 1300 1993.83"
                style={{
                    backgroundColor: "#000",
                }}
                {...props}
            >
                <g fill="#fff">
                    <path
                        d="M1212.24 1006.63c-27.85 0-54.15-12.8-71.32-34.72l-45-57.42c-3.57-4.56-10.04-5.52-14.85-2.29-28.01 18.77-61.24 29.03-95.93 29.01-35.46.02-69.39-10.7-97.77-30.27-3.73-2.57-8.81-1.78-11.6 1.79l-46.39 59.21a90.564 90.564 0 0 1-71.29 34.71l106.59-136.03c6.32-8.06 18.2-8.93 25.62-1.87 25.46 24.2 58.93 37.74 94.84 37.76 35.94-.02 69.4-13.56 94.86-37.76 7.42-7.07 19.31-6.21 25.63 1.86l106.61 136.04Z"/>
                    <path
                        d="M1322.44 1006.63a90.511 90.511 0 0 1-71.25-34.69l-117.87-150.42c-5.18-6.59-11.43-11.45-18.28-14.7-6.85-3.25-14.36-4.9-21.84-4.9s-15.16 1.63-22.06 4.88c-6.92 3.28-13.19 8.11-18.39 14.73l-1.71 2.19-.15.09c-8.18 9.76-18.11 17.29-28.89 22.38-11.62 5.51-24.18 8.26-36.76 8.26s-25.16-2.73-36.8-8.22c-11.65-5.49-22.32-13.77-30.86-24.7-5.18-6.59-11.45-11.43-18.33-14.7-6.9-3.25-14.42-4.9-21.97-4.9s-15.05 1.65-21.95 4.9c-6.88 3.27-13.14 8.11-18.33 14.7L719.13 971.95a90.511 90.511 0 0 1-71.25 34.69L809.7 800.15c8.52-10.91 19.19-19.19 30.82-24.68 11.62-5.51 24.2-8.24 36.76-8.24s25.16 2.73 36.78 8.24a85.374 85.374 0 0 1 30.82 24.68c5.21 6.61 11.45 11.43 18.35 14.7 6.9 3.25 14.44 4.9 22.01 4.9s15.03-1.65 21.91-4.9c6.88-3.27 13.12-8.11 18.3-14.73l1.71-2.19.15-.09a85.32 85.32 0 0 1 29.04-22.43c11.67-5.49 24.27-8.2 36.85-8.2s25.11 2.75 36.72 8.26c11.6 5.49 22.21 13.77 30.71 24.64l161.82 206.52Z"/>
                </g>
                <text
                    transform="translate(444.99 1187.12)"
                    fill="#fff"
                    fontFamily="LilitaOne, 'Lilita One'"
                    fontSize={92}
                >
                    <tspan x={0} y={0}>
                        {"Maffle So"}
                    </tspan>
                    <tspan x={383.08} y={0} letterSpacing="-.03em">
                        {"f"}
                    </tspan>
                    <tspan x={414.82} y={0}>
                        {"tware Consulting"}
                    </tspan>
                </text>
            </svg>

        </Tween>
    </Scene>
)

export default LogoMaffleMobile;
