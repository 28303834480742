import React, {useState} from 'react';
import ServiceCard from "./sub-component/ServiceCard";
import {animated, useChain, useSpring, useSpringRef, useTransition,} from '@react-spring/web'
import Bounce from 'react-reveal/Bounce';
import LoghiWeb from "./sub-component/loghi/LoghiWeb";
import LoghiCloud from "./sub-component/loghi/LoghiCloud";
import LoghiDevops from "./sub-component/loghi/LoghiDevops";
import LoghiSoftware from "./sub-component/loghi/LoghiSoftware";

export const ServiceSection = (props) => {

    const [open, set2] = useState(true)
    const [data] = useState(props.maffleService)
    const [selectedCard, setSelectedCard] = useState(0)
    const [finishEvento, setFinishEvento] = useState(false)

    const countRef = React.useRef(0);
    const countRef2 = React.useRef(0);

    const finishEventoRef = React.useRef(false);



    const springApi = useSpringRef()


    const opacityRef = useSpringRef()

    const opacityEffect = useSpring({
        ref: opacityRef,
        from: {opacity: !finishEvento ? 1 : 0},

        to: {
            opacity: !finishEvento ? 1 : 1,
        },
        reset: true
    })

    const transApi = useSpringRef()
    const transition = useTransition(open ? data : [], {
        ref: transApi,
        trail: 200 / data.length,
        from: {opacity: 0, scale: 0},
        enter: {opacity: 1, scale: 1},
        leave: {opacity: 0, scale: 0},
        onRest: (e) => handleEvent(e),
        onStart: (e) => handleStart(e),

    })
    useChain(open ? [springApi, transApi, opacityRef] : [transApi, springApi, opacityRef], [
        0,
        0,
        0
    ])


    const showCard = (id) => {
        set2(open => !open)
        if (open) {
            //document.body.style.overflow = 'hidden';
        } else {
            setFinishEvento(false)
            finishEventoRef.current = false
            // document.body.style.overflow = 'auto';
        }

        setSelectedCard(id)
        const serviceSection = document.getElementById("all-card-2");
        serviceSection.scrollIntoView();


    }
    const handleStart = (e) => {
        let myDiv = document.getElementById("all-card-2");
        myDiv.style.pointerEvents = 'none';
    }
    const handleEvent = (e) => {

        if (e.value.opacity === 0 && e.value.scale === 0) {
            countRef.current++;
            if (
                countRef.current === data.length && !finishEventoRef.current
            ) {
                setFinishEvento(true)
                finishEventoRef.current = true
                countRef.current = 0;
            } else if (countRef.current === data.length) {
                countRef.current = 0;
            }
        } else if (e.value.opacity === 1 && e.value.scale === 1) {
            countRef2.current++;
            if (
                countRef2.current === data.length

            ) {
                let myDiv = document.getElementById("all-card-2");
                myDiv.style.pointerEvents = 'auto';

                countRef2.current = 0;
            }


        }
    }
    return (

        <section className={`${props.wClass}`} id="service">


            <animated.div style={opacityEffect} className="service-card">


                <div className="all-card container" id="all-card-2">
                    {open && <div className="title"><h3>I nostri focus</h3></div>}

                    <div className="row">
                        {transition((style, item) => (
                            <animated.div
                                className="col-md-6 col-lg-4 my-3 "
                                style={{...style}}
                            >
                                <Bounce left >
                                    <ServiceCard show={showCard} item={item}/>
                                </Bounce>
                            </animated.div>
                        ))}
                    </div>

                </div>
                {finishEvento && <animated.div style={opacityEffect}>
                    <div style={{maxWidth: "1674px"}} className="container">
                        <div className="container-fluid mt-5 mb-5">
                            <div className="row justify-content-center">
                                <div className="col-md-12 mt-2">
                                    <div className="card2"   style={{backgroundColor: `rgba(0 ,0 ,0 ,${props.maffleService.filter(x => x.id === selectedCard)[0].opacity})`,backgroundImage: `url(${process.env.PUBLIC_URL}/`+props.maffleService.filter(x => x.id === selectedCard)[0].img+`)`}}>


                                        <div className={"single-cardv3"}>
                                            <div className={"descrizione-card"}>
                                                {/*<h1> {props.maffleService.filter(x=>x.id==selectedCard)[0].name}</h1>*/}
                                                <div className="title">
                                                    <div className="row">
                                                        <div className="col-sm-11 col-9 mt-2">
                                                            <h1> {props.maffleService.filter(x => x.id === selectedCard)[0].name}</h1>
                                                        </div>
                                                        <div className="col-sm-1 col-3 mt-2">
                                                            <svg
                                                                data-name="Livello 1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 400 387.64"
                                                                onClick={() => showCard(0)}
                                                            >
                                                                <path
                                                                    d="M11.97 183.23v20.99c0 93.98 76.18 170.16 170.16 170.16h27.39c93.98 0 170.16-76.18 170.16-170.16v-20.99c0-93.98-76.18-170.16-170.16-170.16h-27.39c-93.98 0-170.16 76.18-170.16 170.16Zm307.25 35.49H155.29c-7.74 0-11.62 9.36-6.14 14.83l31.94 31.94c1.67 1.67 2.62 3.96 2.55 6.32-.48 14.48-10.05 21.8-19.62 21.8-4.79 0-9.59-1.84-13.24-5.49l-74.89-74.89c-3.12-3.12-5.07-7.34-5.52-12.11-.07-.75-.07-1.51 0-2.26.46-4.77 2.41-8.99 5.52-12.1l78.79-78.79c1.72-1.72 4.09-2.66 6.52-2.54 21.61 1.08 26.96 22.01 16.05 32.92l-28.1 28.1c-5.47 5.47-1.6 14.83 6.14 14.83h158.49c15.44 0 26.53 18.72 11.81 34.68l-6.37 2.76Z"
                                                                    fill="#6eb96a"
                                                                    style={{
                                                                        cursor: "pointer"
                                                                    }}
                                                                />
                                                            </svg>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="long_description">
                                                    <div className="first_section">
                                                        <h3 >
                                                            <div dangerouslySetInnerHTML={{ __html: props.maffleService.filter(x => x.id === selectedCard)[0].first_section }}/>
                                                        </h3>
                                                    </div>

                                                    {
                                                        {
                                                            1: <LoghiWeb/>,
                                                            2:<LoghiCloud/>,
                                                            3:<LoghiDevops/>,
                                                            4:<LoghiSoftware/>
                                                        }[props.maffleService.filter(x => x.id === selectedCard)[0].id]
                                                    }
                                                    <div  className="central_section">

                                                        <h5 > <div  dangerouslySetInnerHTML={{ __html: props.maffleService.filter(x => x.id === selectedCard)[0].central_section }}/>
                                                        </h5>


                                                    </div >
                                                    <div className="last_section">
                                                        <h5>
                                                            <div dangerouslySetInnerHTML={{ __html: props.maffleService.filter(x => x.id === selectedCard)[0].last_section }}/>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </animated.div>}

            </animated.div>

        </section>
    )

}

export default ServiceSection;
