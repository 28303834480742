import React from "react";
import {env} from '../../../configs/EnviromentsConfigs';
import axios from "axios"
import Swal from 'sweetalert2'
import LoaderCaricamento from "../../../component/LoaderCaricamento";

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            errors: {
                name: "",
                subject: "",
                phone: "",
                email: "",
                message: ""
            },
            name: "",
            subject: "",
            phone: "",
            email: "",
            message: ""
        };
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let errors = this.state.errors;
        console.log("name", name)
        switch (name) {
            case "name":
                errors.name = value.length === 0 ? "Il nome non può essere vuoto" : "";
                break;
            case "subject":
                errors.subject = value.length < 5 ? "L'oggetto deve essere di almeno 5 caratteri" : "";
                break;
            case "phone":
                errors.phone = value.length < 10 ? "Il telefono deve contenere almeno 10 cifre" : "";
                break;
            case "email":
                errors.email = value.length === 0 ? "L'email non può essere vuota" : "";
                let appos = value.indexOf("@");
                let dots = value.lastIndexOf(".");

                if (appos < 1 || dots - appos < 2) {
                    errors.email = "Inserire una mail valida";
                }

                break;

            default:
                break;
        }
        this.setState({errors, [name]: value});
    };

    submitHandler = (e) => {
        e.preventDefault();

        if (this.state.name.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Il campo name non può essere vuoto',
                text: '',
                confirmButtonColor: '#68c268'
            })
        } else if (this.state.subject.length === 0) {
            alert("Il campo oggetto non può essere vuoto");
        } else if (this.state.phone.length === 0) {
            alert("Il campo telefono non può essere vuoto");
        } else if (this.state.email.length === 0) {
            alert("Il campo email non può essere vuoto");
        } else if (this.state.message.length === 0) {
            alert("Il campo messaggio non può essere vuoto");
        }

        else if (this.state.errors.name.length !== 0) {
            alert(this.state.errors.name);
        } else if (this.state.errors.subject.length !== 0) {
            alert(this.state.errors.subject);
        } else if (this.state.errors.phone.length !== 0) {
            alert(this.state.errors.phone);
        } else if (this.state.errors.email.length !== 0) {
            alert(this.state.errors.email);
        }
        else {
            this.setState({loading:true})
            console.log("e", this.state)
            let bodyEmail = {
                "nome": this.state.name,
                "cognome": this.state.name,
                "telefono": this.state.phone,
                "descrizione": this.state.message,
                "oggetto": this.state.subject,
                "mail": this.state.email,

                "destinatario": this.state.email,
                "tipologia": "MAIL_TO_CLIENTE"
            }
            axios.post(env.API_ENDPOINT_URL + "/send-mail", bodyEmail).then(response => {


                if (response.status == 200) {
                    this.setState({
                        loading:false,
                        name:"",
                        phone:"",
                        message:"",
                        subject:"",
                        email:""
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Email inviata con successo',
                        text: '',
                        confirmButtonColor: '#68c268'
                    })

                }
                else{
                    this.setState({
                        loading:false
                    })
                    Swal.fire({
                        icon: 'error',
                        title: 'Non è stato possibile recapitare la mail',
                        text: '',
                        confirmButtonColor: '#68c268'
                    })

                }
            })
        }

    };

    render() {
        const {errors,loading} = this.state;
        return (
            <div>
                {loading && <LoaderCaricamento/>}
                <form onSubmit={this.submitHandler.bind(this)} className="form_class">

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="wrap-maffle-input">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={this.state.name}
                                    className="maffle-input"
                                    placeholder="Nome e cognome"
                                    onChange={this.handleChange}
                                />


                                <span className="focus-maffle-input" data-placeholder="&#xe08a;"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="wrap-maffle-input">
                                <input
                                    type="email"
                                    className="maffle-input"
                                    id="email"
                                    name="email"
                                    value={this.state.email}
                                    placeholder="Email"
                                    onChange={this.handleChange}
                                />
                                <span className="focus-maffle-input" data-placeholder="&#xe010;"/>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="wrap-maffle-input">
                                <input
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    value={this.state.subject}
                                    className="maffle-input"
                                    placeholder="Oggetto"
                                    onChange={this.handleChange}
                                />
                                <span className="focus-maffle-input" data-placeholder="&#xe078;"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="wrap-maffle-input">
                                <input
                                    type="text"
                                    className="maffle-input"
                                    id="phone"
                                    name="phone"
                                    value={this.state.phone}

                                    placeholder="Telefono"
                                    onChange={this.handleChange}
                                />
                                <span className="focus-maffle-input" data-placeholder="&#xe00b;"/>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-maffle-input">

                          <textarea
                              name="message"
                              id="message"
                              value={this.state.message}
                              className="maffle-input-text"
                              placeholder="Messaggio ..."
                              onChange={this.handleChange}
                          >

                          </textarea>
                        <span className="focus-maffle-input" data-placeholder="&#x6b;"/>
                    </div>
                    <button type="submit" className="button-19">
                        Invia il messaggio
                    </button>
                </form>
            </div>
        );
    }
}

export default Form;
