import React, {useContext, useState} from "react";
import {env} from '../../../configs/EnviromentsConfigs';
import axios from "axios"
import Swal from 'sweetalert2'
import LoaderCaricamento from "../../../component/LoaderCaricamento";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {AuthContext} from "../../../context/AuthContext";
import {useHistory} from "react-router-dom";
import iconConfig from "../../../configs/iconConfig";
import toastMessage from "../../../configs/toastMessage";
const initialValues = {
    email: '',
    password: '',
};
const LoginForm =()=> {
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const history = useHistory();
    const validationSchema = Yup.object().shape({
        email: Yup.string().required("il campo email è obbligatorio"),
        password: Yup.string().required("Il campo password è obbligatorio"),
    });
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (values, actions) => {

        try {
            const response = await axios.post(env.API_AUTH_URL + "/",
                {
                    AuthFlow: 'USER_PASSWORD_AUTH',
                    AuthParameters: {
                        USERNAME: values.email,
                        PASSWORD: values.password
                    },
                    ClientId: env.aws_user_pools_web_client_id
                },
                {
                    headers: {
                        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
                        "Content-Type": "application/x-amz-json-1.1"
                    }
                })
            const data = response.data
            const challenge = (data?.ChallengeName === "NEW_PASSWORD_REQUIRED")
            if (challenge) {


                const sessionID=data.Session
                window.location.href = `/password-change?sessionId=${sessionID}&email=${values.email}`;


            } else {
                const token = data?.AuthenticationResult?.IdToken
                const expire = data?.AuthenticationResult?.ExpiresIn
                console.log("expire",expire)
                console.log("token",token)
                if(token!==undefined && token!==null) {
                    const loginEmail = JSON.parse(atob(token?.split('.')[1])).email
                    sessionStorage.setItem("token", token);
                    sessionStorage.setItem("auth_token_expire", expire);
                    sessionStorage.setItem("auth_email", loginEmail);
                    setIsAuthenticated(true);
                    window.location.href = '/profile-page'
                }
                else{
                    toastMessage({"esito": "KO", "message": `Autenticazione fallita`})
                }
            }
        }catch (e) {
            toastMessage({"esito": "KO", "message": `Autenticazione fallita`})
        }


    };
    return (


        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}

            >
                {({ isSubmitting,values }) => (
                    <Form>


                        <div className="wrap-maffle-input">
                            <Field
                                type="email"
                                name="email"
                                className="maffle-input"
                                placeholder="Email"
                            />

                            <span className="focus-maffle-input" data-placeholder="&#xe010;"/>

                        </div>


                        <div className="wrap-maffle-input" style={{display: "flex", alignItems: "center"}}>
                            <Field
                                type={showPassword ? "text" : "password"}
                                name="password"
                                className="maffle-input"
                                placeholder="Password"

                            />

                            <span
                                className="focus-maffle-input"
                                data-placeholder="&#xe001;"

                            >
                            </span>
                            {showPassword ?
                                <img
                                    src={iconConfig.hidenIcon}
                                    onClick={() => setShowPassword(!showPassword)}
                                    style={{cursor:"pointer",width:"20px",height:"20px"}}
                                /> :
                                <img
                                    src={iconConfig.showIcon}
                                    onClick={() => setShowPassword(!showPassword)}
                                    style={{cursor:"pointer",width:"20px",height:"20px"}}
                                />
                            }

                        </div>
                        <div style={{marginBottom: "10px"}}>
                            <ErrorMessage name="email" component="div" className="text-red-500" style={{
                                color: "red",
                                fontWeight: "500",
                                fontSize: ".75rem",
                                textAlign: "start",
                                marginLeft: "1vw"
                            }}/>
                            <ErrorMessage name="password" component="div" className="text-red-500" style={{
                                color: "red",
                                fontWeight: "500",
                                fontSize: ".75rem",
                                textAlign: "start",
                                marginLeft: "1vw"
                            }}/>
                        </div>
                        <button type="submit" className="button-19">
                            Login
                        </button>

                    </Form>
                )}
            </Formik>

        </div>
    );

}

export default LoginForm;
