// iconConfig.js

import {toast} from "react-hot-toast";

const toastMessage = (info) => {
    console.log(info)
    if (info.esito === "KO") {
        toast.error(info.message,{
            style: {
                border: '1px solid black',
                padding: '16px',
            },
            iconTheme: {
                primary: 'red',
                secondary: 'white',
            },
        })
    } else {
        //toast.success(info.message)
        toast.success(info.message, {
            style: {
                border: '1px solid black',
                padding: '16px',
            },
            iconTheme: {
                primary: 'green',
                secondary: 'white',
            },
        });
    }


}

export default toastMessage;
