// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import {validaToken} from "../utilis/utils";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const checkAuthStatus = () => {

            const token = sessionStorage.getItem('token');
            if(validaToken(token)) {
                setIsAuthenticated(true);
            }
            else{
                setIsAuthenticated(false)
            }
        };

        checkAuthStatus();
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, loading, setLoading }}>
            {children}
        </AuthContext.Provider>
    );
};