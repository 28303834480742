import React, {useEffect, useState} from 'react';


export const BannerSection = (props) => {

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    const isMobile = width <= 768;

    return (

        <section
            className="frelencer_banner_area developer_banner_area"
            id="about"
            style={{
                background: 'linear-gradient( 180deg, rgb(0 0 0) 0%, rgb(160 160 160) 100%)'
            }}
        >

            <div className="container">
                <div className="row"
                     style={{ position: "relative"}}>
                    <div className="col-12 col-lg-6 col-md-6"
                         style={{
                             display: "flex",
                             alignItems: "center",
                             minHeight: "50vh",
                             flexDirection: "column-reverse",
                             mixBlendMode: "soft-light"
                         }}
                    >

                        {!isMobile ?

                            <div>
                                <div>
                                    <img src={"/img/maffle-img/home/banner-section/img1.jpg"}
                                         style={{width: "100%"}}
                                    />
                                </div>

                                <hr align="left" size="1" width="300" color="white"
                                    style={{
                                        borderWidth: "5px",
                                        width: "100%"
                                    }}
                                />

                                <div>
                                    <img src={"/img/maffle-img/home/banner-section/img1.jpg"}
                                         style={{width: "25%", opacity: 0}}
                                    />
                                </div>
                            </div>
                            :

                            <div>
                                <div>
                                    <img src={"/img/maffle-img/home/banner-section/img1.jpg"}
                                         style={{width: "100%"}}
                                    />
                                    <hr align="left" size="1" width="300" color="white"
                                        style={{
                                            borderWidth: "5px",
                                            width: "100%"
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <div className="col-12 col-lg-6 col-md-6"
                         style={{
                             display: "flex",
                             alignItems: "center",
                             flexDirection: "column-reverse",
                             mixBlendMode: "soft-light"

                         }}
                    >

                        {!isMobile ?

                            <div>

                                <div>
                                    <img src={"/img/maffle-img/home/banner-section/img1.jpg"}
                                         style={{width: "25%", opacity: 0}}
                                    />
                                </div>
                                <hr align="left" size="1" width="300" color="white"
                                    style={{
                                        borderWidth: "5px",
                                        width: "100%"
                                    }}
                                />
                                <div>
                                    <img src={"/img/maffle-img/home/banner-section/img2.jpg"}
                                         style={{width: "100%"}}
                                    />
                                </div>

                            </div>
                            :
                            <div>
                                <img src={"/img/maffle-img/home/banner-section/img2.jpg"}
                                     style={{width: "100%"}}
                                />
                            </div>
                        }

                    </div>

                    <div className="h1_slogan"
                    >
                        PENSA
                        <br/>
                        PROGETTA
                        <br/>
                        DIGITALIZZA

                    </div>
                </div>

            </div>
        </section>
    );
}

export default BannerSection;
