import React, {Component} from "react";
import Reveal from "react-reveal/Reveal";
import Form from "./sub-component/Form";


class ContactSection extends Component {
    render() {

        return (<section className={`${this.props.wClass}`} id="contact">
            <div className="container">
                <div className="title">
                    <h3>Contattaci</h3>
                </div>

                <div className="container-fluid mt-5 mb-5">
                    <div className="row justify-content-center">
                        <div className="col-md-12 mt-2">
                            <div className="card">
                                <div className="card-body"
                                     style={{backgroundColor: "rgb(0 0 0 / 87%)"}}
                                >
                                    <div className="col-md-12">
                                        <Reveal effect="fadeInRight" duration={800}>
                                            <div className="input_form">
                                                <h4></h4>

                                                <Form/>

                                            </div>
                                        </Reveal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
    }
}

export default ContactSection;
