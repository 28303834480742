import React, {useState} from 'react';
import ListTable from "./sub-component/ListTable";

export const ListProductPurchase = ({data}) => {


    return (

        <section className={`product-purchase`}>

            <ListTable data={data}/>

        </section>
    )

}

export default ListProductPurchase;
