import React, {useEffect, useState} from 'react';
import MetaTags from "react-meta-tags";
//LAYOUT
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
//SECTION
import ServiceSection from "../sections/service-section/ServiceSection";
import ProductSection from "../sections/product-section/ProductSection";

import BannerSection from '../sections/banner-section/BannerSection';
import SloganSection from "../sections/slogan-section/SloganSection";
import FaqsSection from "../sections/faqs-section/FaqsSection";
import ContactSection from "../sections/contact-section/ContactSection";


import maffleData from '../configs/data/maffleData';
import maffleServiceData from "../configs/data/maffleServiceData";
import maffleProductData from "../configs/data/maffleProductData";
import faqsData from "../configs/data/faqsData"
import {useHistory} from "react-router-dom";


export const Home = () => {
    const history = useHistory();
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 768;

    useEffect(() => {
        function updateSize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);

    })



    return (


        <div className="body_wrapper" style={{backgroundColor: "black"}}>
            <MetaTags>
                <title>Maffle Software Consulting - Home</title>
                <meta
                    name="description"
                    content="Maffle Software Consulting, Bring your business idea to life"

                />
                <meta name="keywords"
                      content="maffle, mafflesoftwareconsulting, software, web, react, cloud, devops , development"/>
                <meta name="robots" content="index,follow"/>
            </MetaTags>

            <div id={"scrollIcon"} className="scroll"></div>

            <Navbar mContainer="custome_container"/>

            <SloganSection isMobile={isMobile}/>

            <BannerSection aClass="about_area" isMobile={isMobile}/>

            <ServiceSection wClass="card-service-section" maffleService={maffleServiceData}/>

            <ProductSection wClass="product-section" maffleProducData={maffleProductData}/>

            <FaqsSection faqsData={faqsData} fClass="faqs_section"/>

            <ContactSection wClass="contact-section"/>
            <div className="width80">
                <div className="divider light"/>
            </div>

            <Footer maffleData={maffleData}/>
        </div>

    )
}


