import React, {useEffect} from 'react';
import {Controller, Scene} from "react-scrollmagic";
import {SplitChars, Timeline, Tween} from "react-gsap";
import LogoMaffleMobile from "./LogoMobile";

const listaFrase = ["BRING", "YOUR BUSINESS", "IDEA", "TO LIFE"]
export const LogoConSloganMobile = (props) => {

    return (
        <div className="banner_content">
            <Controller>
                <LogoMaffleMobile/>
                <Scene
                    duration={1500}
                    triggerHook={0.3}
                    pin={{pushFollowers: false}}
                    indicators={false}
                >
                    {(progress) => (
                        <div id="slogan" className="mx-auto">
                            <Timeline totalProgress={progress} paused>
                                <Timeline>
                                    <Tween
                                        from={{
                                            x: '-100vw',
                                        }}
                                        to={{
                                            x: '2vw',
                                        }}
                                        stagger={0.5}>
                                        <br/>
                                        <br/>
                                        <br/>

                                        <SplitChars
                                            wrapper={
                                                <center
                                                    style={{
                                                        display: 'inline-block',
                                                        fontSize: "32px",
                                                        color: "white",
                                                        letterSpacing: "6px",
                                                        lineHeight: "1.8"
                                                    }}
                                                    className='myriad-hebrew'
                                                />
                                            }
                                        >
                                            BRING
                                        </SplitChars>
                                        <br/>
                                        <SplitChars
                                            wrapper={
                                                <center
                                                    style={{
                                                        display: 'inline-block',
                                                        fontSize: "32px",
                                                        color: "white",
                                                        letterSpacing: "8px",
                                                        lineHeight: "1.8"
                                                    }}
                                                    className='myriad-hebrew'
                                                />
                                            }
                                        >
                                            YOUR
                                        </SplitChars>
                                        <br/>
                                        <SplitChars
                                            wrapper={
                                                <center
                                                    style={{
                                                        display: 'inline-block',
                                                        fontSize: "32px",
                                                        color: "white",
                                                        letterSpacing: "8px",
                                                        lineHeight: "1.8"
                                                    }}
                                                    className='myriad-hebrew'
                                                />
                                            }
                                        >
                                            BUSINESS
                                        </SplitChars>
                                        <br/>
                                        <SplitChars
                                            wrapper={
                                                <center
                                                    style={{
                                                        display: 'inline-block',
                                                        fontSize: "32px",
                                                        color: "white",
                                                        letterSpacing: "8px",
                                                        lineHeight: "1.8"
                                                    }}
                                                    className='myriad-hebrew'
                                                />
                                            }
                                        >
                                            IDEA
                                        </SplitChars>
                                        <br/>
                                        <SplitChars
                                            wrapper={
                                                <center
                                                    style={{
                                                        display: 'inline-block',
                                                        fontSize: "32px",
                                                        color: "white",
                                                        letterSpacing: "8px",
                                                        lineHeight: "1.8"
                                                    }}
                                                    className='myriad-hebrew'
                                                />
                                            }
                                        >
                                            TO &nbsp; LIFE
                                        </SplitChars>
                                        <br/>
                                    </Tween>
                                </Timeline>
                            </Timeline>
                        </div>
                    )}
                </Scene>
            </Controller>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    );
}

export default LogoConSloganMobile;