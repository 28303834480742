const maffleData = {
  socialLinks: [
    {
      name: "Facebook",
      url: "https://www.facebook.com/profile.php?id=100091476459764",
      className: "social_facebook",
    },
    {
      name: "instagram",
      url: "https://instagram.com/mafflesoftwareconsulting?igshid=YmMyMTA2M2Y=",
      className: "social_instagram",
    },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/company/maffle-software-consulting/",
      className: "social_linkedin",
    }
  ],


  contacttitle: "Inviaci una mail",
};

export default maffleData;
