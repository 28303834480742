import React, {Component} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Home} from "./pages/Home";
import {ProfilePage} from "./pages/ProfilePage";
import SuccessPage from "./pages/SuccessPage";
import {LoginPage} from "./pages/LoginPage";
import ProtectedRoute from "./component/ProtectedRoute";
import {PasswordChallengePage} from "./pages/PasswordChallengePage";

class App extends Component {
    render() {
        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        {/* PUBLIC ROUTE*/}
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/login" component={LoginPage}/>
                        <Route exact path="/password-change" component={PasswordChallengePage}/>
                        {/*PROTECTED ROUTE*/}
                        <ProtectedRoute exact path="/profile-page/success" component={SuccessPage}/>
                        <ProtectedRoute exact path="/profile-page" component={ProfilePage} />
                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}

export default App;
