
import canceled from '../assets/icon/canceled.png';
import active from '../assets/icon/active.png';
import show from '../assets/icon/show.png';
import hiden from '../assets/icon/hiden.png';
import buyButton from '../assets/icon/buy-button.png';
import buyButtonv2s1 from '../assets/icon/buy_32.png';
import buyButtonv2s2 from '../assets/icon/buy.png';
const iconConfig = {
    canceledIcon: canceled,
    activeIcon: active,
    showIcon:show,
    hidenIcon:hiden,
    buyButton:buyButton,
    buyButtonv2:buyButtonv2s1,
    buyButtonv2s2:buyButtonv2s2
};

export default iconConfig;
