import React, {useState} from 'react';
import {animated, useChain, useSpring, useSpringRef, useTransition,} from '@react-spring/web'
import Bounce from 'react-reveal/Bounce';
import ProductCard from "./sub-component/ProductCard";
import {env} from '../../configs/EnviromentsConfigs';
export const ProductProfileSection = (props) => {

    const maffleProducData = props.maffleProducData
    //STATE
    const [open, set2] = useState(true)
    const [selectedCard, setSelectedCard] = useState(0)
    const [finishEvento, setFinishEvento] = useState(false)

    //REF
    const countRef = React.useRef(0);
    const countRef2 = React.useRef(0);
    const finishEventoRef = React.useRef(false);

    //SPRING REF
    const springApi = useSpringRef()
    const opacityRef = useSpringRef()
    const opacityEffect = useSpring({
        ref: opacityRef,
        from: {opacity: !finishEvento ? 1 : 0},

        to: {
            opacity: !finishEvento ? 1 : 1,
        },
        reset: true
    })
    const transApi = useSpringRef()
    //TRANSITION
    const transition = useTransition(open ? maffleProducData : [], {
        ref: transApi,
        trail: 200 / maffleProducData.length,
        from: {opacity: 0, scale: 0},
        enter: {opacity: 1, scale: 1},
        leave: {opacity: 0, scale: 0},
        onRest: (e) => handleEvent(e),
        onStart: (e) => handleStart(e),

    })
    useChain(open ? [springApi, transApi, opacityRef] : [transApi, springApi, opacityRef], [
        0,
        0,
        0
    ])


    const handleStart = (e) => {
        let myDiv = document.getElementById("all-card-2");
        myDiv.style.pointerEvents = 'none';
    }
    const handleEvent = (e) => {

        if (e.value.opacity === 0 && e.value.scale === 0) {
            countRef.current++;
            if (
                countRef.current === maffleProducData.length && !finishEventoRef.current
            ) {
                setFinishEvento(true)
                finishEventoRef.current = true
                countRef.current = 0;
            } else if (countRef.current === maffleProducData.length) {
                countRef.current = 0;
            }
        } else if (e.value.opacity === 1 && e.value.scale === 1) {
            countRef2.current++;
            if (
                countRef2.current === maffleProducData.length

            ) {
                let myDiv = document.getElementById("all-card-2");
                myDiv.style.pointerEvents = 'auto';

                countRef2.current = 0;
            }


        }
    }
    const showCard = (id) => {
        set2(open => !open)
        if (open) {

        } else {
            setFinishEvento(false)
            finishEventoRef.current = false

        }
        setSelectedCard(id)

    }
    return (

        <section className={`${props.wClass}`} id="product">


            <animated.div style={opacityEffect} className="service-card">


                <div className="all-card container" id="all-card-2">


                    <div className="row">
                        {transition((style, item) => (
                            <animated.div
                                className="col-md-6 col-lg-4 my-3 "
                                style={{...style}}
                            >
                                <Bounce left >
                                    <ProductCard show={showCard} item={item}/>
                                </Bounce>
                            </animated.div>
                        ))}
                    </div>

                </div>
                {finishEvento && <animated.div style={opacityEffect}>
                    <div  onClick={() => showCard(0)} style={{fontFamily:'Lilita One',padding:" 0 25px 25px",cursor: "pointer",color:"black", textDecoration: "underline"}}>
                        TORNA ALLA LISTA DEI PRODOTTI
                    </div>
                    <div className="background">
                        <div className="container">
                            <div className="panel pricing-table">

                                {maffleProducData.filter(x => x.id === selectedCard)[0].plans.map(plan=> {
                                    return(
                                        <div className="pricing-plan">
                                            <h2 className="pricing-header">
                                                {plan.name}
                                            </h2>

                                            <div className="pricing-features">
                                                {plan.features.map(feature=>{
                                                    return(
                                                        <div className="pricing-features-item">
                                                            {feature}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div style={{display: "flex",alignItems: "center",justifyContent: "space-around"}}>
                                                {plan.plans.map(singlePlan=>{
                                                    return(

                                                        <form
                                                            action={`${env.API_ENDPOINT_URL}/maffle/create-checkout-session`}
                                                            method="POST"
                                                        >
                                                            <input
                                                                type="text"
                                                                name="price"
                                                                hidden={true}
                                                                value={singlePlan.price[env.PRICE_KEY]}
                                                            />
                                                            <input
                                                                type="text"
                                                                name="mode"
                                                                hidden={true}
                                                                value={"subscription"}
                                                            />
                                                            <button tmlType="submit" className="button-19" >{singlePlan.name}</button>
                                                        </form>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}


                            </div>
                        </div>

                    </div>
                </animated.div>
                }

            </animated.div>

        </section>
    )

}

export default ProductProfileSection;
