import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import {validaToken} from "../utilis/utils";

const ProtectedRoute = ({ component: Component, ...rest }) => {

    const  {isAuthenticated}  = useContext(AuthContext);
    let myAutenticated = false
    const token = sessionStorage.getItem("token")
    console.log("validaToken(token)",validaToken(token))
    if(validaToken(token)){
        myAutenticated=true
    }
    return (
        <Route
            {...rest}
            render={(props) =>
                myAutenticated ? (
                    <Component key={props.location.key} {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

export default ProtectedRoute;