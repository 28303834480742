const maffleServiceData = [
    {
        "id": 1,
        "name": "Protear",
        "description": "Software per aziende audioprotesiche",
        "img":"img/maffle-img/home/product-section/protear-logo.svg",
        "siteLink":"https://www.protear.it",
        "socialLinks": [
            {
                "name": "instagram",
                "url": "https://www.instagram.com/protearsoftware/",
                "className": "social_instagram",
            },
        ],
        "plans":[
            {
                "name": "Light",
                "plans": [
                    {
                        "name":"Mensile",
                        "price": {
                            "test":"price_1P1CggJ5Q4L2IzvQ5sixVok8",
                            "live":"price_1P2UzlJ5Q4L2IzvQCw4M5cxF"
                        }
                    },
                    {
                        "name":"Annuale",
                        "price": {
                            "test":"price_1P1CpPJ5Q4L2IzvQbqlgn2kJ",
                            "live":"price_1P2UzlJ5Q4L2IzvQQJka4vBO"
                        }
                    }
                ],
                "features":[
                    'Fino a 2500 pazienti',
                    'Uno store'
                ]
            },
            {
                "name":"Medium",
                "plans": [
                    {
                        "name":"Mensile",
                        "price": {
                            "test":"price_1P1ChYJ5Q4L2IzvQhlEgCJWO",
                            "live":"price_1P2UzlJ5Q4L2IzvQyKKYdRZB"
                        }
                    },
                    {
                        "name":"Annuale",
                        "price": {
                            "test":"price_1P1CpiJ5Q4L2IzvQaXXqePY7",
                            "live":"price_1P2UzlJ5Q4L2IzvQ6iPjw5Ug"
                        }
                    }
                ],
                "features":[
                    'Fino a 10000 pazienti',
                    'Fino a 3 store'
                ]
            },
            {
                "name":"Premium",
                "plans": [
                    {
                        "name":"Mensile",
                        "price": {
                            "test":"price_1P1ChhJ5Q4L2IzvQxYLt6BkV",
                            "live":"price_1P2UzlJ5Q4L2IzvQiCMdxoHE"
                        }
                    },
                    {
                        "name":"Annuale",
                        "price": {
                            "test":"price_1P1Cq0J5Q4L2IzvQe16nenRi",
                            "live":"price_1P2UzlJ5Q4L2IzvQCvS92xPf"
                        }
                    }
                ],
                "features":[
                    'Pazienti illimitati',
                    'Store illimitati'
                ]
            }
        ]
    },

];

export default maffleServiceData;
