import React, {useEffect, useState} from 'react';
//LAYOUT
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
//SECTION
import maffleData from '../configs/data/maffleData';
import maffleProductData from "../configs/data/maffleProductData";
import ProductProfileSection from "../sections/product-profile-section/ProductProfileSection";
import {Toaster} from "react-hot-toast";
import qs from "qs";
import toastMessage from "../configs/toastMessage";
import {useLocation} from "react-router-dom";
import ListProductPurchase from "../sections/list-product-purchase/ListProductPurchase";
import axios from "axios";
import {env} from "../configs/EnviromentsConfigs";
import LoaderCaricamento from "../component/LoaderCaricamento";



export const ProfilePage = () => {
    const location = useLocation();
    const [loading,setLoading] = useState(null)
    const [purchases,setPurchases] = useState([])
    const canceled = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    }).canceled;
    useEffect(() => {
        if(canceled){
            console.log("messaggio")
            toastMessage({"esito": "KO", "message": `Ops...Pagamento non riuscito`})
        }
        async function fetchData() {
            try {
                setLoading(true)
                const token = sessionStorage.getItem("token")
                const response = await axios.get(env.API_ENDPOINT_URL + "/maffle/get-all-purchase",{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                console.log("RISULTATO: ",response?.data?.risultato)
                if(response?.data?.risultato!==undefined){
                    setPurchases(response?.data?.risultato)
                }

            } catch (e) {
                console.error("eccezione", e);

            }
            finally {
                setLoading(false)
            }
        }
        fetchData();
    }, []);

    /*useEffect( async  () => {
        console.log("canceled",canceled)
        if(canceled){
            console.log("messaggio")
            toastMessage({"esito": "KO", "message": `Ops...Pagamento non riuscito`})
        }
        try {
            setLoading(true)
            const token = sessionStorage.getItem("token")
            const response = await axios.get(env.API_ENDPOINT_URL + "/maffle/get-all-purchase",{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            console.log("RISULTATO: ",response?.data?.risultato)
            if(response?.data?.risultato!==undefined){
                setPurchases(response?.data?.risultato)
            }

        } catch (e) {
            console.error("eccezione", e);

        }
        finally {
            setLoading(false)
        }
    }, []);*/



    return (


        <div className="body_wrapper" style={{backgroundColor: "black"}}>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <Navbar mContainer="custome_container"/>

            <section className="profile-page-area">

                {loading &&
                    <LoaderCaricamento/>
                }
                {!loading &&
                <div className="tabs">
                    <div className="tab-2">
                        <label htmlFor="tab2-1">PRODOTTI</label>
                        <input id="tab2-1" name="tabs-two" type="radio" defaultChecked="checked"/>
                        <div>
                            <section id="lists" className="tab-panel">
                                <ProductProfileSection wClass="product-profile-section"
                                                       maffleProducData={maffleProductData}/>
                            </section>
                        </div>
                    </div>
                    <div className="tab-2">
                        <label htmlFor="tab2-2">LISTA ACQUISTI</label>
                        <input id="tab2-2" name="tabs-two" type="radio"/>
                        <div>
                            <section id="productpurchase" className="tab-panel">

                                {purchases.length > 0 &&
                                    <ListProductPurchase data={purchases}/>
                                }
                                {purchases.length === 0 &&
                                    <div style={{
                                        fontFamily: 'Lilita One',
                                        fontSize: "30px",
                                        color: "black",
                                        textDecoration: "underline"
                                    }}>
                                        Non hai acquistato nessun prodotto
                                    </div>
                                }
                            </section>
                        </div>
                    </div>
                </div>
                }
            </section>
            <Footer maffleData={maffleData}/>
        </div>

    )
}


