import React, {Component} from "react";
import {Link} from "react-scroll";
import {useHistory, withRouter} from "react-router-dom";
import iconConfig from "../configs/iconConfig";
import buyButtonv2 from "../assets/icon/buy_32.png";

const Navbar =(props)=> {

    const history = useHistory();
    const {location,mClass, mContainer} = props;
    const handleExit = () =>{
        console.log("exit")
        sessionStorage.clear()
        window.location.href = `/`;
    }
    return (
        <nav className={`navbar navbar-expand-lg navbar-light ${mClass}`}>
            <div className={`container ${mContainer}`}>
                <a className="navbar-brand logo_h" href="/">
                    <img
                        id="maffleLogo" className="maffleLogo"
                        src={"img/maffle-img/loghi/logo-white.png"}
                        alt=""
                        style={{opacity: location.pathname.includes("profile-page") || location.pathname.includes("login") || location.pathname.includes("password-change")?1:0}}
                    />
                </a>
                {!location.pathname.includes("profile-page") && !location.pathname.includes("login") && !location.pathname.includes("password-change") &&

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                }
                {!location.pathname.includes("profile-page") && !location.pathname.includes("login") && !location.pathname.includes("password-change") &&
                    <div
                        className="collapse navbar-collapse offset"
                        id="navbarSupportedContent"
                    >
                        <ul className="nav navbar-nav m-auto">

                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    activeClass="active"
                                    to="home"
                                    spy={true}
                                    smooth={true}
                                    offset={-86}
                                    duration={500}
                                >
                                    Home

                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    activeClass="active"
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={-86}
                                    duration={500}
                                >
                                    Il nostro obiettivo
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    activeClass="active"
                                    to="service"
                                    spy={true}
                                    smooth={true}
                                    offset={-86}
                                    duration={500}
                                >
                                    Cosa facciamo
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    activeClass="active"
                                    to="product"
                                    spy={true}
                                    smooth={true}
                                    offset={-86}
                                    duration={500}
                                >
                                    I nostri prodotti
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    activeClass="active"
                                    to="faqs"
                                    spy={true}
                                    smooth={true}
                                    offset={-86}
                                    duration={500}
                                >
                                    FAQs
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    activeClass="active"
                                    to="contact"
                                    spy={true}
                                    smooth={true}
                                    offset={-86}
                                    duration={500}
                                >
                                    Contattaci
                                </Link>
                            </li>
                        </ul>
                        <div className="navbar-nav-icon-buy" style={{display:"flex",justifyContent: "center"}}>
                            <img
                                onClick={() => history.push("/profile-page")}
                                style={{cursor:"pointer"}}
                                src={iconConfig.buyButtonv2s2}
                                alt="icona clean"
                            />
                        </div>
                        <div className="nav-item-highlighter"/>
                    </div>
                }


                {location.pathname !== "/" && location.pathname !== "/login" && location.pathname !== "/password-change" &&
                    <div>
                        <button onClick={()=>handleExit()} className="button-59" > ESCI</button>
                    </div>
                }

            </div>

        </nav>

    );

}

export default withRouter(Navbar);
