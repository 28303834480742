const maffleServiceData = [
    {
        "id": 1,
        "name": "Sviluppo Web",
        "description": "Sei alla ricerca di una azienda esperta in sviluppo web in grado di offrirti soluzioni personalizzate e innovative? Non cercare oltre, siamo qui per mettere a tua disposizione la nostra competenza!",
        "classNameIcon": "web-development",
        "img":"img/maffle-img/card/sfondi/web.png",
        "opacity":0.65,
        "first_section":"Siamo una software house specializzata nello sviluppo di applicazioni web. Utilizziamo le migliori tecnologie del settore, come <b> React, Node.js, Java, Python, Next.js e Angular. </b> ",
        "central_section":"Il nostro team si tiene costantemente aggiornato sulle ultime tendenze del settore e usa la <b>  metodologia di sviluppo agile </b> per lavorare in modo collaborativo. Non ci lasciamo spaventare da nessuna sfida e siamo pronti a mettere al tuo servizio la nostra esperienza e competenza, l'obiettivo è creare software innovativo e all'avanguardia che soddisfi le esigenze dei clienti. ",
        "last_section":"Non importa quale sia la tua richiesta, siamo qui per supportarti in ogni aspetto dello sviluppo web, offrendoti soluzioni personalizzate che rispondano alle tue esigenze. Contattaci oggi stesso per discutere delle tue idee e scoprire come possiamo aiutarti a creare il sito web perfetto per la tua attività.",

    },{
        "id": 2,
        "name": "Gestione in Cloud",
        "description": "Porta la tua azienda al livello successivo con le nostre soluzioni in cloud. Scopri come possiamo migliorare la produttività, la sicurezza e l'efficienza della tua azienda oggi stesso!",
       "classNameIcon": "cloud",
        "opacity":0.65,
        "first_section":"Ci specializziamo nella creazione, progettazione e gestione di soluzioni architetturali in <b> cloud</b> per applicazioni di grandi e medie aziende. Il nostro team è a tua completa disposizione per garantirti una soluzione altamente scalabile, affidabile e sicura.\n",
        "central_section":"<ul> <li><b> Scalabile: </b> Possiamo adattarci alle esigenze della tua azienda man mano che essa cresce, garantendo sempre performance e qualità del servizio ottimali</li>" +
            "<li><b>Affidabile: </b> Progettata per funzionare in modo continuo, evitando qualsiasi tipo di downtime che potrebbe causare problemi e interruzioni alle attività aziendali</li>" +
            "<li><b>Sicura: </b>Implementazione di tutte le misure necessarie per proteggere i dati sensibili della tua azienda e prevenire eventuali violazioni della sicurezza informatica. </li></ul>",
        "last_section":"Se desideri una soluzione in cloud affidabile, sicura e di alta qualità per la tua azienda, non esitare a contattarci. Con il nostro aiuto, il successo della tua azienda è a portata di mano!\n",
        "img":"img/maffle-img/card/sfondi/cloud.jpg"
    }, {
        "id": 3,
        "name": "DevOps",
        "description": "Automatizziamo i processi di sviluppo, testing e distribuzione, garantendo maggiore efficienza e qualità del software. Scopri come la nostra expertise può portare la tua azienda alla massima efficienza.",
       "classNameIcon": "devops",
        "opacity":0.25,
        "first_section":"Maffle Software Consulting fornisce servizi per aiutare ad ottimizzare la gestione dei sistemi informatici. Il nostro team è in grado di gestire le esigenze di configurazione, deployment e monitoraggio delle infrastrutture utilizzando tecnologie avanzate come <b>Docker, Kubernetes, Ansible, Jenkins e Terraform. </b> ",
        "central_section":"DevOps è una vera e propria <b> filosofia di sviluppo del software</b>. Ragionare in modalità DevOps significa adottare una filosofia che promuove la collaborazione e la comunicazione tra sviluppatori e operatori IT, al fine di raggiungere un obiettivo comune: ridurre i tempi di rilascio del software e migliorare la qualità del prodotto.",
        "last_section":"Come azienda, siamo consapevoli della complessità che può comportare l'adozione della filosofia DevOps e degli ostacoli che possono sorgere nel processo di implementazione. Tuttavia, crediamo fermamente nei benefici che questo approccio può apportare alle aziende e ai loro prodotti software. </br>" +
            "Se desideri migliorare il tuo ambiente di sviluppo e produzione, non esitare a contattarci. Il nostro team di esperti DevOps sarà felice di discutere con te le tue esigenze e fornirti una soluzione personalizzata per il tuo business. Siamo pronti ad aiutarti a raggiungere il successo!</br>",
        "img":"img/maffle-img/card/sfondi/devops.jpg"
    },
    {
        "id": 4,
        "name": "Software cross-platfrom",
        "description": "Scopri il nostro servizio di sviluppo software multi piattaforma! Creiamo applicazioni su misura per te, non solo web, ma anche desktop, mobile e altro ancora.",
       "classNameIcon": "software-ad-hoc",
        "opacity":0.65,
        "first_section":"Offriamo soluzioni cross-platfrom per il business dei nostri clienti. ",
        "central_section":"Siamo consapevoli che non esiste un software universale in grado di soddisfare tutte le esigenze di ogni singolo cliente, ecco perché ci dedichiamo alla creazione di soluzioni software su misura.</br>Ci impegniamo nell'affiancare il cliente nell'analisi dei requisiti e di scegliere con lui la migliore piattaforma possibile per il delivery dell'applicazione.",
        "last_section":"In sintesi, il nostro servizio di sviluppo software personalizzato è la soluzione ideale per le aziende che cercano di ottenere i risultati che otterrebbero con una web applcation anche su altri tipi di piattaforma (mobile, desktop).</br>",
        "img":"img/maffle-img/card/sfondi/software.jpg"
    }
];

export default maffleServiceData;
