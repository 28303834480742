import React from 'react';

export const LoghiWeb = (props) => {

    return (

        <div className="group-icon">
            <img className="img-custom" style={{width:"6.5%",marginRight:"5px"}} src={"/img/maffle-img/loghitech/software/electron.png"}/>
            <img className="img-custom" style={{width:"8.5%",marginRight:"5px"}} src={"/img/maffle-img/loghitech/software/flutter.jpg"}/>
            <img className="img-custom" style={{width:"6.5%",marginRight:"5px"}} src={"/img/maffle-img/loghitech/software/react.png"}/>


        </div>
    );
}

export default LoghiWeb;
