import React, {useContext, useEffect, useState} from 'react';
//LAYOUT
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
//SECTION
import maffleData from '../configs/data/maffleData';
import {Toaster} from "react-hot-toast";
import {useHistory, useLocation} from "react-router-dom";
import Reveal from "react-reveal/Reveal";
import {ErrorMessage, Field, Form, Formik} from "formik";
import iconConfig from "../configs/iconConfig";
import * as Yup from "yup";
import axios from "axios";
import {env} from "../configs/EnviromentsConfigs";
import qs from "qs";
import {AuthContext} from "../context/AuthContext";
import toastMessage from "../configs/toastMessage";
const initialValues = {
    password1: '',
    password2: '',
};
export const PasswordChallengePage = () => {
    const location = useLocation();
    const sessionId = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    }).sessionId;
    const email = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    }).email;

    useEffect(async () => {
        if(sessionId===undefined || sessionId===null || email===undefined || email===null){
            window.location.href = `/`;
        }

    }, []);
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

    const validationSchema = Yup.object().shape({

        password1: Yup.string()
            .required("Il campo password è obbligatorio")
            .min(8, 'La password deve contenere almeno 8 caratteri')
            .matches(/(?=.*[0-9])/, 'La password deve contenere almeno un numero')
            .matches(/(?=.*[A-Z])/, 'La password deve contenere almeno un carattere maiuscolo'),
        password2: Yup.string()
            .required("Il campo Conferma password è obbligatorio")
            .oneOf([Yup.ref('password1'), null], 'Le password devono coincidere'),
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const handleSubmit = async (values, actions) => {
        console.log("values",values)

        try {
            const response = await axios.post(env.API_AUTH_URL + "/",
                {
                    "ChallengeName": "NEW_PASSWORD_REQUIRED",
                    "ChallengeResponses":  {
                        "NEW_PASSWORD": values.password1,
                        "USERNAME": email
                    },
                    "ClientId": env.aws_user_pools_web_client_id,
                    "Session": sessionId
                },
                {
                    headers: {
                        "X-Amz-Target": "AWSCognitoIdentityProviderService.RespondToAuthChallenge",
                        "Content-Type": "application/x-amz-json-1.1"
                    }
                })
            const data = response.data
            const token = data?.AuthenticationResult?.IdToken
            const expire = data?.AuthenticationResult?.ExpiresIn
            if(token!==undefined && token!==null) {
                const loginEmail = JSON.parse(atob(token?.split('.')[1])).email
                sessionStorage.setItem("token", token);
                sessionStorage.setItem("auth_token_expire", expire);
                sessionStorage.setItem("auth_email", loginEmail);
                setIsAuthenticated(true);
                window.location.href = '/profile-page'
            }
            else{
                toastMessage({"esito": "KO", "message": `Ops...Qualcosa è andato storto`})
            }

        }catch (e) {
            toastMessage({"esito": "KO", "message": `Ops...Qualcosa è andato storto (${e.message})`})
        }


    };
    return (


        <div className="body_wrapper">

            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Navbar mContainer="custome_container"/>
            <div style={{minHeight: "85vh"}}>
                <section className={`contact-section`} id="login-section"
                         style={{alignItems: "center", justifyContent: "center", display: "flex"}}>
                    <div>
                        <div className="card" style={{minWidth: "30vw"}}>
                            <div className="card-body"
                                 style={{backgroundColor: "rgb(0 0 0 / 87%)"}}
                            >
                                <div className="col-md-12">
                                    <Reveal effect="fadeInRight" duration={800}>
                                        <div className="input_form">
                                            <div style={{
                                                fontFamily: "Lilita One",
                                                color: "white",
                                                fontSize: "20px",
                                                textAlign: "center",
                                                marginTop: "15px",
                                                marginBottom: "15px"
                                            }}>
                                                CAMBIO PASSWORD
                                            </div>
                                            <div style={{
                                                fontFamily: "Lilita One",
                                                color: "white",
                                                fontSize: "15px",
                                                textAlign: "center",
                                                marginTop: "5px",
                                                marginBottom: "15px"
                                            }}>
                                                È obbligatorio modificare la password al momento del primo accesso
                                            </div>
                                            <div>
                                                <Formik
                                                    initialValues={initialValues}
                                                    validationSchema={validationSchema}
                                                    onSubmit={handleSubmit}

                                                >
                                                    {({isSubmitting, values}) => (
                                                        <Form>


                                                            <div className="wrap-maffle-input"
                                                                 style={{display: "flex", alignItems: "center"}}>
                                                                <Field
                                                                    type={showPassword ? "text" : "password"}
                                                                    name="password1"
                                                                    className="maffle-input"
                                                                    placeholder="Password"

                                                                />

                                                                <span
                                                                    className="focus-maffle-input"
                                                                    data-placeholder="&#xe001;"

                                                                >
                                                                </span>
                                                                {showPassword ?
                                                                    <img
                                                                        src={iconConfig.hidenIcon}
                                                                        onClick={() => setShowPassword(!showPassword)}
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            width: "20px",
                                                                            height: "20px"
                                                                        }}
                                                                    /> :
                                                                    <img
                                                                        src={iconConfig.showIcon}
                                                                        onClick={() => setShowPassword(!showPassword)}
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            width: "20px",
                                                                            height: "20px"
                                                                        }}
                                                                    />
                                                                }

                                                            </div>

                                                            <div className="wrap-maffle-input"
                                                                 style={{display: "flex", alignItems: "center"}}>
                                                                <Field
                                                                    type={showPassword2 ? "text" : "password"}
                                                                    name="password2"
                                                                    className="maffle-input"
                                                                    placeholder="Conferma Password"

                                                                />

                                                                <span
                                                                    className="focus-maffle-input"
                                                                    data-placeholder="&#xe001;"

                                                                >
                                                                </span>
                                                                {showPassword2 ?
                                                                    <img
                                                                        src={iconConfig.hidenIcon}
                                                                        onClick={() => setShowPassword2(!showPassword2)}
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            width: "20px",
                                                                            height: "20px"
                                                                        }}
                                                                    /> :
                                                                    <img
                                                                        src={iconConfig.showIcon}
                                                                        onClick={() => setShowPassword2(!showPassword2)}
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            width: "20px",
                                                                            height: "20px"
                                                                        }}
                                                                    />
                                                                }

                                                            </div>
                                                            <div style={{marginBottom: "10px"}}>

                                                                <ErrorMessage name="password1" component="div"
                                                                              className="text-red-500" style={{
                                                                    color: "red",
                                                                    fontWeight: "500",
                                                                    fontSize: ".75rem",
                                                                    textAlign: "start",
                                                                    marginLeft: "1vw"
                                                                }}/>
                                                                <ErrorMessage name="password2" component="div"
                                                                              className="text-red-500" style={{
                                                                    color: "red",
                                                                    fontWeight: "500",
                                                                    fontSize: ".75rem",
                                                                    textAlign: "start",
                                                                    marginLeft: "1vw"
                                                                }}/>
                                                            </div>
                                                            <button type="submit" className="button-19">
                                                                Login
                                                            </button>

                                                        </Form>
                                                    )}
                                                </Formik>

                                            </div>

                                        </div>
                                    </Reveal>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer maffleData={maffleData}/>
        </div>

    )
}


