import React, {Component} from 'react';

class ServiceCard extends Component {

    render() {
        return(

            <div className="card" onClick={()=>this.props.show(this.props.item.id)}>
                <div className="icon" >
                    <i className={this.props.item.classNameIcon}></i>
                </div>
                <div className="card-content">
                    <h5> {this.props.item.name}</h5>
                    <p >
                        {this.props.item.description}
                    </p>
                    <h6> CLICCA PER APPROFONDIRE...</h6>
                </div>


            </div>

        )
    }
}

export default ServiceCard;
