
const faqs = [
    {
        id: 1,
        header: "Quanto costa sviluppare un software su misura?",
        text: `Ogni progetto è unico, le funzionalità di cui ha bisogno e la sua complessità di sviluppo ne stabiliscono il costo. \n
                Hai bisogno di un preventivo? Contattaci per qualsiasi esigenza e provvederemo a fornirti maggiori informazioni.`
    },
    {
        id: 2,
        header: "Perché sviluppare un software su misura?",
        text: `I software in commercio non potranno adattarsi perfettamente al tuo flusso di lavoro, proprio perché i processi sono diversi ed unici per ogni Azienda. Con un software personalizzato potrai soddisfare tutte le tue esigenze di Business, anche in corso d’opera, dal momento che sarai incluso nello sviluppo e costantemente aggiornato. A progetto concluso avrai un software di tua proprietà che potrai gestire come meglio credi.`
    },
    {
        id: 3,
        header: "Quali sono i principali vantaggi del cloud computing rispetto all'hosting tradizionale?",
        text: `Il cloud computing offre una maggiore scalabilità, flessibilità, affidabilità e sicurezza rispetto all'hosting tradizionale. Inoltre, consente di ridurre i costi, migliorare l'efficienza operativa e aumentare la velocità di sviluppo e rilascio dei servizi.`
    },
    {
        id: 4,
        header: "Quali sono i principali strumenti utilizzati per l'approccio DevOps e perché dovrei usufruirne?",
        text: `I principali strumenti DevOps includono Jenkins, Ansible, Docker, Kubernetes, Terraform e molti altri. Questi strumenti vengono adattati ed utilizzati in base all'ambiente target (cloud o macchine on premise) e consente di migliorare la qualità dei servizi, ridurre i costi, aumentare la velocità di rilascio e migliorare la collaborazione tra gli sviluppatori e gli operatori IT`
    }
]
export default faqs;