import React, {Component} from 'react';

class ProductCard extends Component {

    render() {
        return(

            <div className="card-container">
                <div className="card">
                    <div className="imgBx">
                        <img style={{paddingRight:"25px",paddingLeft:"25px",paddingTop:"15px"}} src={this.props.item.img}/>
                    </div>
                    <div className="contentBx">
                        <h2 style={{marginBottom: "30px"}}>{this.props.item.name}</h2>
                        <div className="size" style={{marginBottom: "30px"}}>

                            {
                                this.props.item.socialLinks.map((social,index) => {
                                    return (
                                        <span key={index} onClick={() => window.open(social.url, '_blank')}>
                                            <i className={social.className}></i>
                                        </span>
                                    )
                                })
                            }
                            <span onClick={() => window.open("/profile-page")}>
                                <i className="icon_currency"></i>
                            </span>
                        </div>
                        <a style={{cursor: "pointer"}} onClick={() => window.open(this.props.item.siteLink, '_blank')}>
                            VISITA IL SITO
                        </a>
                    </div>
                </div>
            </div>


        )
    }
}

export default ProductCard;
