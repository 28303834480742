import React from 'react';


export const LoghiWeb = (props) => {

    return (

        <div className="group-icon">
            <img className="img-custom" style={{width:"6.5%",marginRight:"15px"}} src={"/img/maffle-img/loghitech/devops/ansible.jpg"}/>
            <img className="img-custom" style={{width:"5.5%",marginRight:"15px"}} src={"/img/maffle-img/loghitech/devops/docker.webp"}/>
            <img className="img-custom" style={{width:"6.5%",marginRight:"15px"}} src={"/img/maffle-img/loghitech/devops/gitlab.png"}/>
            <img className="img-custom" style={{width:"4.9%",marginRight:"15px"}} src={"/img/maffle-img/loghitech/devops/jenkins.png"}/>
            <img className="img-custom" style={{width:"6.5%",marginRight:"15px"}} src={"/img/maffle-img/loghitech/devops/kubernetes.png"}/>
            <img className="img-custom"  style={{width:"6.5%",marginRight:"15px"}} src={"/img/maffle-img/loghitech/devops/terraform.webp"}/>

        </div>
    );
}

export default LoghiWeb;
