import React, {Component} from 'react';

class ProductCard extends Component {

    render() {
        return(

            <div className="box">
                <div className="box-image">
                    <img src={this.props.item.img} alt="" style={{paddingRight:"50px",paddingLeft:"50px",paddingTop:"50px",paddingBottom:"50px"}}/>
                </div>
                <div className="studio-button">

                    <div onClick={()=>this.props.show(this.props.item.id)} className="studio-button-label">
                        ACQUISTA
                    </div>
                </div>
            </div>


        )
    }
}

export default ProductCard;
