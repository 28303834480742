import React, { useEffect, useState } from "react";
import { useLocation, useHistory  } from "react-router-dom";
import {Toaster} from "react-hot-toast";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import maffleData from "../configs/data/maffleData";
import axios from "axios";
import {env} from "../configs/EnviromentsConfigs";
import Swal from "sweetalert2";
import LoaderCaricamento from "../component/LoaderCaricamento";
const qs = require("qs");

const SuccessPage = () => {

  const location = useLocation();
  const history = useHistory();
  const sessionId = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).sessionId;

  //state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    sessionId !== undefined
        ? buy(sessionId)
        : history.push('/profile-page');
  }, []);

  async function buy(sessionId) {


    try {
      setLoading(true)

      const token = sessionStorage.getItem("token")
      const response = await axios.post(env.API_ENDPOINT_URL + "/maffle/create-purchase", {
        sessionId:sessionId
      },{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })


      if (response.data.esito === "KO") {
         history.push(`/profile-page?canceled=true`);
      }

      setLoading(false);

    } catch (e) {
      console.error("eccezione", e);
      history.push(`/profile-page?canceled=true`);
    }
  }


  return (
      <>
        <div className="body_wrapper" style={{backgroundColor: "black"}}>

          <Toaster
              position="top-center"
              reverseOrder={false}
          />

          <Navbar mContainer="custom_container"/>

          <section className="success-page">
            <div className={"container-card"}>
              {loading && <LoaderCaricamento/>}
              {!loading  && (
                  <div style={{padding: "23px"}}>
                    <div onClick={()=>history.push(`/profile-page`)} style={{cursor:"pointer",fontFamily:'Lilita One',fontSize:"30px",color:"black", textDecoration: "underline",marginBottom: "3vh"}}>
                      Ritorna alla pagina del profilo
                    </div>
                    <div className="card">
                      <div style={{borderRadius:"200px", height:"200px", width:"200px", background: "#F8FAF5", margin:"0 auto"}}>
                        <i className="checkmark">✓</i>
                      </div>
                      <h1>Pagamento avvenuto con successo</h1>
                      <p>
                        {"Numero ordine: " + sessionId + ""}
                      </p>
                    </div>
                  </div>
              )}

            </div>
          </section>
          <Footer maffleData={maffleData}/>
        </div>
      </>
  );
};

export default SuccessPage;
