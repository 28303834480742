import React, {useEffect} from 'react';
import {Parallax} from 'react-parallax';
import LogoConSloganDesktop from "./sub-component/LogoConSloganDesktop";
import LogoConSloganMobile from "./sub-component/LogoConSloganMobile";

export const SloganSection = ({isMobile}) => {

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
    }, []);

    const onScroll = () => {
        if(document.getElementById("slogan")!==null && document.getElementById("slogan")!==undefined) {
            let start = document.getElementById("slogan").offsetTop;

            if (start > 0) {
                document.getElementById("maffleLogo").style.opacity = "1";

            } else {
                document.getElementById("maffleLogo").style.opacity = "0";
            }

            if (start > 321)
                document.getElementById("scrollIcon").style.opacity = "0";
            else
                document.getElementById("scrollIcon").style.opacity = "1";
        }

    };

    return (
        <section id="home">
            <Parallax strength={0} className="banner_area"
                      style={{backgroundColor: "black"}}
            >
                <div className="container">
                    {isMobile ?
                        <LogoConSloganMobile isMobile={isMobile}/>
                        :
                        <LogoConSloganDesktop/>
                    }
                </div>
            </Parallax>
        </section>
    );
}

export default SloganSection;