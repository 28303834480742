import axios from "axios";

export const validaToken = (token) => {
    console.log("### VALIDAZIONE TOKEN ###")
    let isValid = false;
    if(token!==undefined && token !==null && token.length>0){

        try {
            const tokenSections = sessionStorage.getItem("token").split('.');
            if (tokenSections.length < 3) {
                throw new Error('Token non valido');
            }
            isValid=true

            console.log("RITORNO TOKEN",isValid)
            return isValid

        } catch (error) {
            sessionStorage.clear()
            isValid=false

            console.error('Errore durante la verifica del token:', error);
            console.log("RITORNO TOKEN",isValid)
            return isValid
        }
    }else{
        sessionStorage.clear()
        isValid=false

        console.log("RITORNO TOKEN",isValid)
        return isValid
    }

};
