const locale ={
    API_ENDPOINT_URL: 'http://localhost:8080',
    PRICE_KEY:'test',
    API_AUTH_URL:'https://cognito-idp.eu-central-1.amazonaws.com',
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_web_client_id: '6p4d8ml6icghi0o8ht0d8e2p8f',
    aws_user_pool_id:'eu-central-1_XgWkyL3LU'
}

const sviluppo = {
    API_ENDPOINT_URL: 'https://5p8slj28cj.execute-api.eu-central-1.amazonaws.com/latest',
    PRICE_KEY:'test',
    API_AUTH_URL:'https://cognito-idp.eu-central-1.amazonaws.com',
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_web_client_id: '6p4d8ml6icghi0o8ht0d8e2p8f',
};


const collaudo = {
    API_ENDPOINT_URL: 'https://hjd7vvcpb3.execute-api.eu-central-1.amazonaws.com/latest',
    PRICE_KEY:'test',
    API_AUTH_URL:'https://cognito-idp.eu-central-1.amazonaws.com',
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_web_client_id: '21a7htr5723t3mvkr0d5bo5b8g',
};

const produzione = {
    API_ENDPOINT_URL: 'https://yrq0qnl8rh.execute-api.eu-central-1.amazonaws.com/latest',
    PRICE_KEY:'live',
    API_AUTH_URL:'https://cognito-idp.eu-central-1.amazonaws.com',
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_web_client_id: '3a0hje8mt3rrhtp4q51i860n9h',
};

const getEnv = () => {
    console.log("ENV",process.env.REACT_APP_ENV)
    switch (process.env.REACT_APP_ENV?.trim()) {
        case 'sviluppo':
            return sviluppo
        case 'collaudo':
            return collaudo
        case 'produzione':
            return produzione
        default:
            return locale
    }
};

export const env = getEnv()