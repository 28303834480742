import React, {useEffect, useState} from 'react';
//LAYOUT
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
//SECTION
import maffleData from '../configs/data/maffleData';
import {Toaster} from "react-hot-toast";
import {useHistory, useLocation} from "react-router-dom";
import LoginSection from "../sections/login-section/LoginSection";

export const LoginPage = () => {
    const location = useLocation();
    const history = useHistory();




    return (


        <div className="body_wrapper">

            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Navbar mContainer="custome_container"/>
            <div style={{minHeight: "70vh"}}>
                <LoginSection wClass="contact-section"/>
            </div>

            <Footer maffleData={maffleData}/>
        </div>

    )
}


