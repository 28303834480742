import React from 'react';
import {Controller, Scene} from "react-scrollmagic";
import {SplitChars, Timeline, Tween} from "react-gsap";
import LogoMaffle from "./LogoMaffle";

export const LogoConSlogan = (props) => {

    return (
            <div className="banner_content">
                <Controller>
                    <LogoMaffle/>
                    <Scene
                        duration={1500}
                        triggerHook={0.3}
                        pin={{pushFollowers: false}}
                        indicators={false}
                    >
                        {(progress) => (
                            <div id="slogan" className="mx-auto">
                                <Timeline totalProgress={progress} paused>
                                    <Timeline>
                                        <Tween
                                            from={{
                                                x: '-100vw',
                                            }}
                                            to={{
                                                x: '2vw',
                                            }}
                                            stagger={0.5}>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <SplitChars
                                                wrapper={
                                                    <center
                                                        style={{
                                                            display: 'inline-block',
                                                            fontSize: '32px',
                                                            color: "white",
                                                            letterSpacing: "8px"
                                                        }}
                                                        className='myriad-hebrew'
                                                    />
                                                }
                                            >
                                                BRING &nbsp; YOUR &nbsp; BUSINESS &nbsp; IDEA &nbsp; TO &nbsp; LIFE
                                            </SplitChars>
                                            <br/>
                                        </Tween>
                                    </Timeline>
                                </Timeline>
                            </div>
                        )}
                    </Scene>
                </Controller>

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
    );

}

export default LogoConSlogan;