import React, {Component} from 'react';
import Spinner from "react-bootstrap/Spinner";

class LoaderCaricamento extends Component {
    render() {
        return (
            <div className="loader-wrapper">
                <Spinner style={{backgroundColor: "transparent"}} animation="grow" role="status">
                    <img style={{"width":"200%"}} src={"/img/maffle-img/loghi/logo-white-trasparent.png"} className="App-logo" alt="logo" />
                </Spinner>
            </div>
        )
    }
}

export default LoaderCaricamento;