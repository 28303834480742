import React, {Component} from "react";
import Reveal from "react-reveal/Reveal";
import LoginForm from "./sub-component/LoginForm";
import {useHistory} from "react-router-dom";


const LoginSection = (props)=> {
    const history = useHistory();


        return (
            <section className={`${props.wClass}`} id="login-section" style={{alignItems: "center",justifyContent: "center",display:"flex"}}>
                <div >
                <div className="card" style={{minWidth: "30vw"}}>
                    <div className="card-body"
                         style={{backgroundColor: "rgb(0 0 0 / 87%)"}}
                    >
                        <div className="col-md-12">
                            <Reveal effect="fadeInRight" duration={800}>
                                <div className="input_form">
                                    <div style={{fontFamily:"Lilita One",color:"white",fontSize:"20px",textAlign:"center",marginTop:"15px",marginBottom:"15px"}}>LOGIN</div>

                                    <LoginForm/>

                                </div>
                            </Reveal>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        );

}

export default LoginSection;
