import React, {useState} from 'react';
import {animated, useChain, useSpring, useSpringRef, useTransition,} from '@react-spring/web'
import Bounce from 'react-reveal/Bounce';
import ProductCard from "./sub-component/ProductCard";

export const ProductSection = (props) => {

    const maffleProducData = props.maffleProducData
    //STATE
    const [open, set2] = useState(true)
    const [finishEvento, setFinishEvento] = useState(false)

    //REF
    const countRef = React.useRef(0);
    const countRef2 = React.useRef(0);
    const finishEventoRef = React.useRef(false);

    //SPRING REF
    const springApi = useSpringRef()
    const opacityRef = useSpringRef()
    const opacityEffect = useSpring({
        ref: opacityRef,
        from: {opacity: !finishEvento ? 1 : 0},

        to: {
            opacity: !finishEvento ? 1 : 1,
        },
        reset: true
    })
    const transApi = useSpringRef()
    //TRANSITION
    const transition = useTransition(open ? maffleProducData : [], {
        ref: transApi,
        trail: 200 / maffleProducData.length,
        from: {opacity: 0, scale: 0},
        enter: {opacity: 1, scale: 1},
        leave: {opacity: 0, scale: 0},
        onRest: (e) => handleEvent(e),
        onStart: (e) => handleStart(e),

    })
    useChain(open ? [springApi, transApi, opacityRef] : [transApi, springApi, opacityRef], [
        0,
        0,
        0
    ])


    const handleStart = (e) => {
        let myDiv = document.getElementById("all-card-2");
        myDiv.style.pointerEvents = 'none';
    }
    const handleEvent = (e) => {

        if (e.value.opacity === 0 && e.value.scale === 0) {
            countRef.current++;
            if (
                countRef.current === maffleProducData.length && !finishEventoRef.current
            ) {
                setFinishEvento(true)
                finishEventoRef.current = true
                countRef.current = 0;
            } else if (countRef.current === maffleProducData.length) {
                countRef.current = 0;
            }
        } else if (e.value.opacity === 1 && e.value.scale === 1) {
            countRef2.current++;
            if (
                countRef2.current === maffleProducData.length

            ) {
                let myDiv = document.getElementById("all-card-2");
                myDiv.style.pointerEvents = 'auto';

                countRef2.current = 0;
            }


        }
    }
    return (

        <section className={`${props.wClass}`} id="product">


            <animated.div style={opacityEffect} className="service-card">


                <div className="all-card container" id="all-card-2">
                    {open && <div className="title"><h3>I nostri prodotti</h3></div>}

                    <div className="row">
                        {transition((style, item) => (
                            <animated.div
                                className="col-md-6 col-lg-4 my-3 "
                                style={{...style}}
                            >
                                <Bounce left >
                                    <ProductCard item={item}/>
                                </Bounce>
                            </animated.div>
                        ))}
                    </div>

                </div>

            </animated.div>

        </section>
    )

}

export default ProductSection;
