import React from 'react';

export const LoghiWeb = (props) => {

    return (

        <div className="group-icon">
            <img className="img-custom" style={{width:"9.5%",marginRight:"0px"}} src={"/img/maffle-img/loghitech/cloud/aws.png"}/>
            <img className="img-custom" style={{width:"9.5%",marginRight:"5px"}} src={"/img/maffle-img/loghitech/cloud/gpc.png"}/>
            <img className="img-custom" style={{width:"6.5%",marginRight:"5px"}} src={"/img/maffle-img/loghitech/cloud/azure.png"}/>
            <img className="img-custom" style={{width:"8.5%",marginRight:"5px"}} src={"/img/maffle-img/loghitech/cloud/alibaba.png"}/>
            <img className="img-custom" style={{width:"6.3%",marginRight:"5px"}} src={"/img/maffle-img/loghitech/cloud/ibm.png"}/>
        </div>
    );
}

export default LoghiWeb;
