import React from 'react';
import iconConfig from "../../../configs/iconConfig";

export const  ListTable =({data})=> {

    const getInterval = (interval) =>{
        let italianInterval = interval
        if(interval==="year"){
            italianInterval="annuale"
        }else if(interval==="month"){
            italianInterval="mensile"
        }
        return italianInterval
    }
    return (

        <div className="container">
            <ul className="responsive-table">
                <li className="table-header">
                    <div className="col col-1">Id</div>
                    <div className="col col-2">Acquisto</div>
                    <div className="col col-3">Scadenza</div>
                    <div className="col col-4">Stato</div>
                </li>
                {data.map((purchase,index) => {
                        return(
                            <li key={index} className="table-row">
                                <div className="col col-1" data-label="Id">{purchase.id.split("_")[1]}</div>
                                <div className="col col-2" data-label="Acquisto">{purchase.dataAcquisto}</div>
                                <div className="col col-3" data-label="Scadenza">{purchase.expire}{" ("}{getInterval(purchase.interval)}{" )"}</div>
                                <div className="col col-3" data-label="Stato">
                                    {purchase.status === "active" ?
                                        <img
                                            src={iconConfig.activeIcon}
                                            alt="icona clean"
                                        />
                                        :
                                        <img
                                            src={iconConfig.canceledIcon}
                                            alt="icona clean"
                                        />}
                                </div>
                            </li>
                        )
                    }
                )}

            </ul>
        </div>


    )

}

export default ListTable;
