import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import maffleData from "../configs/data/maffleData";

class Footer extends Component {
    render() {
        let maffleData = this.props.maffleData;
        return (
            <section className="footer-area">

                <div className="container">
                    <div className="footer-content">
                        <Link to="/" className="logo wow fadeInDown" data-wow-delay="0.4s">
                            <img src="img/maffle-img/loghi/logo-white.png" alt=""/>
                        </Link>
                        <Fade top cascade>
                            <ul className="list_style">
                                {
                                    maffleData.socialLinks.map(item => {
                                        return (
                                            <li key={item.name}>
                                                <a target="_blank" href={item.url}><i className={item.className}></i></a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </Fade>

                        <h6></h6>
                        <p>© 2023 Maffle Software Consulting S.R.L - All Rights Reserved</p>
                        <h6></h6>
                        <p>Recapiti telefonici: <b>+39 3343207650 | +39 3317918275 | +39 3461852514 </b></p>
                        <h6></h6>
                        <p>Sede legale: Via Sassola 42, Sant'Antonio Abate (NA)</p>
                        <h6></h6>
                        <p>P. IVA: 10221421216</p>
                        <h6></h6>
                        {/*<p> <Link to="/privacy"> Privacy e policy</Link></p>*/}
                    </div>
                </div>
            </section>
        )
    }
}

export default Footer;