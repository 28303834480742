import React from 'react';


export const LoghiWeb = (props) => {

    return (

        <div className="group-icon" >
            <img className="img-custom" style={{width:"6.5%",marginRight:"5px"}} src={"/img/maffle-img/loghitech/web/angular.png"}/>
            <img className="img-custom" style={{width:"6.5%",marginRight:"5px"}} src={"/img/maffle-img/loghitech/web/dynamo.png"}/>
            <img className="img-custom" style={{width:"6.5%",marginRight:"10px"}} src={"/img/maffle-img/loghitech/web/nodejs.jpg"}/>
            <img className="img-custom" style={{width:"5.3%",marginRight:"10px"}} src={"/img/maffle-img/loghitech/web/next.webp"}/>

            <img className="img-custom" style={{width:"6.5%",marginRight:"10px"}} src={"/img/maffle-img/loghitech/web/mongo.png"}/>
            <img className="img-custom" style={{width:"5.5%",marginRight:"13px"}} src={"/img/maffle-img/loghitech/web/react.png"}/>
            <img className="img-custom" style={{width:"5.3%",marginRight:"10px"}} src={"/img/maffle-img/loghitech/web/spring.png"}/>

        </div>
    );
}

export default LoghiWeb;
